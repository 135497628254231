import { computed } from "vue";
import { useStore } from "vuex";
import en from "@/locales/en.json";
import th from "@/locales/th.json";

export function translate(key) {
  const store = useStore();
  const language = computed(() => {
    return store.state.auth.language;
  });
  let message;
  language.value === "th" ? (message = th) : (message = en);

  return message[key] ? message[key] : "-";
}
