<template>
  <header class="header z-1">
    <button class="btn-setting" type="button" @click="handleOpen">
      <i class="bi bi-gear-fill"></i>
    </button>
    <SettingComponent
      v-if="display.open"
      :data="display"
      @on-apply="handleApply"
      @on-back="display.open = false"
      @on-logout="handleLogout"
      @on-open-site="handleOpenSite"
      @on-open-phase="handleOpenPhase"
      @on-open-theme="handleOpenTheme"
      @on-open-language="handleOpenLanguage"
    />
    <SettingSiteComponent
      v-if="display.openSite"
      :data="display"
      @on-apply="handleApplySite"
      @on-back="display.openSite = false"
    />
    <SettingPhaseComponent
      v-if="display.openPhase"
      :data="display"
      @on-apply="handleApplyPhase"
      @on-back="display.openPhase = false"
    />
    <SettingThemeComponent
      v-if="display.openTheme"
      :option="display.option.theme"
      :value="display.formState.themeId"
      @on-apply="handleApplyTheme"
      @on-back="display.openTheme = false"
    />
    <SettingLanguageComponent
      v-if="display.openLanguage"
      :value="display.formState.language"
      @on-apply="handleApplyLanguage"
      @on-back="display.openLanguage = false"
    />
  </header>
</template>

<style scoped>
.header {
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  padding: 1.575rem 0;
  display: flex;
  align-items: center;
}
.btn-setting {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: var(--color-button-light);
  border-radius: 0.6rem 0 0 0.6rem;
  width: 2.1rem;
  height: 2.1rem;
  border: 0;
  padding: 0;
}
.btn-setting i {
  color: var(--color-green);
  font-size: 1rem;
  vertical-align: middle;
}
.btn-setting i::before {
  -webkit-animation: 1s gear-rotate linear infinite;
  -moz-animation: 1s gear-rotate linear infinite;
  animation: 1s gear-rotate linear infinite;
}
@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
  }
}
@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
h4,
.h4 {
  font-size: 3.5rem;
  line-height: normal;
}
h5,
.h5 {
  font-size: 2.5rem;
  line-height: normal;
}
</style>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SettingComponent from "@/components/SettingComponent.vue";
import SettingSiteComponent from "@/components/SettingSiteComponent.vue";
import SettingPhaseComponent from "@/components/SettingPhaseComponent.vue";
import SettingThemeComponent from "@/components/SettingThemeComponent.vue";
import SettingLanguageComponent from "@/components/SettingLanguageComponent.vue";
const store = useStore();
const display = computed(() => {
  return store.state.display;
});

async function handleOpen() {
  await store.dispatch("display/handleOpen");
}
function handleOpenSite() {
  store.dispatch("display/handleOpenSite");
}
function handleOpenPhase() {
  store.dispatch("display/handleOpenPhase");
}
function handleOpenTheme() {
  store.dispatch("display/handleOpenTheme");
}
function handleOpenLanguage() {
  store.dispatch("display/handleOpenLanguage");
}
async function handleApply() {
  await store.dispatch("display/handleApply");
}
function handleApplySite(value) {
  store.dispatch("display/handleApplySite", value);
}
function handleApplyPhase(value) {
  store.dispatch("display/handleApplyPhase", value);
}
function handleApplyTheme(value) {
  store.dispatch("display/handleApplyTheme", value);
}
function handleApplyLanguage(value) {
  store.dispatch("display/handleApplyLanguage", value);
}
async function handleLogout() {
  await store.dispatch("display/handleLogout");
}
</script>
