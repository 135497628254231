import { dataCarbon, dataEnergy, dataSaving } from "@/services";
import { formatUnit, formatNumber } from "@/helpers";

export default {
  namespaced: true,
  state: {
    energy: {
      solar: 0,
      solarRatio: 0,
      grid: 0,
      gridRatio: 0,
    },
    carbon: {
      co2: 0,
      tree: 0,
      water: 0,
      energy: 0,
    },
    saving: 0,
  },
  actions: {
    async getCarbon({ commit }) {
      try {
        const response = await dataCarbon();
        if (response.code === 0) {
          commit("SET_CARBON", {
            co2: response.data.co2,
            tree: formatUnit(response.data.tree, "tree"),
            water: response.data.water,
            energy: response.data.energy,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEnergy({ commit }) {
      try {
        const response = await dataEnergy();
        if (response.code === 0) {
          commit("SET_ENERGY", {
            solar: response.data.solar,
            solarRatio:
              formatNumber(
                (response.data.solar /
                  (response.data.solar + response.data.grid)) *
                  100,
                2
              ) + "%",
            grid: response.data.grid,
            gridRatio:
              formatNumber(
                (response.data.grid /
                  (response.data.solar + response.data.grid)) *
                  100,
                2
              ) + "%",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSaving({ commit }) {
      try {
        const response = await dataSaving();
        if (response.code === 0) {
          commit(
            "SET_SAVING",
            !isNaN(response.data.saving) ? +response.data.saving : 0
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_ENERGY(state, data) {
      state.energy = data;
    },
    SET_CARBON(state, data) {
      state.carbon = data;
    },
    SET_SAVING(state, data) {
      state.saving = data;
    },
  },
};
