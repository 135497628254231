<template>
  <div class="offcanvas bg-gradient offcanvas-end show">
    <div class="offcanvas-header">
      <div class="d-flex align-items-center">
        <button class="setting-btn p-0" @click="$emit('onBack')">
          <i class="bi bi-arrow-left d-flex"></i>
        </button>
        <div class="setting-title ff-kittithada fw-medium ms-3">
          {{ translate("title_language") }}
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-0">
      <div
        :class="
          selected === item.value
            ? 'setting-card-radio-language position-relative overflow-hidden selected'
            : 'setting-card-radio-language position-relative overflow-hidden'
        "
        v-for="item in option"
        :key="item.value"
      >
        <input
          type="radio"
          name="language"
          :value="item.value"
          v-model="selected"
        />
        <div class="d-flex align-items-center">
          <div
            class="logo overflow-hidden d-flex align-items-center justify-content-center"
          >
            <img
              :src="item.value === 'en' ? FlagEn : FlagTh"
              alt="flag"
              class="img-fluid"
            />
          </div>
          <div class="setting-subtitle ff-kittithada ms-3">
            {{ formatLanguageName(item.value) }}
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <div class="d-flex justify-content-between">
        <div>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="$emit('onBack')"
          >
            <i class="bi bi-chevron-left"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_back")
            }}</span>
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('onApply', selected)"
          >
            <i class="bi bi-check-lg"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_done")
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.setting-card-radio-language {
  padding: 1.667rem 2rem;
}
.setting-card-radio-language.selected {
  background-color: #d8fbf6;
}
.setting-card-radio-language .logo {
  width: 2rem;
  height: 2rem;
}
.setting-card-radio-language .logo img {
  max-height: 100%;
  width: auto;
}
.setting-card-radio-language input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -1.667rem -2rem;
}
.setting-card-radio-language input::before {
  content: "";
  height: 1.667rem;
  width: 1.667rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  border: 0.083rem solid #e5e5e5;
  border-radius: 50%;
  transform: translate(0, -50%);
}
.setting-card-radio-language input::after {
  content: "";
  height: 0.833rem;
  width: 0.833rem;
  position: absolute;
  top: 50%;
  right: calc(2rem + 0.4rem);
  border-radius: 50%;
  transform: translate(0, -50%);
}
.setting-card-radio-language input:checked:before {
  border-color: var(--color-green);
}
.setting-card-radio-language input:checked:after {
  background-color: var(--color-green);
}
</style>

<script setup>
import { defineProps, ref } from "vue";
import { translate } from "@/services";
import { formatLanguageName } from "@/helpers";
import FlagEn from "@/assets/flags/en.png";
import FlagTh from "@/assets/flags/th.png";

let props = defineProps({
  value: String,
});
const selected = ref(props.value);
const option = ref([
  {
    value: "en",
  },
  {
    value: "th",
  },
]);
</script>
