import {
  authRegister,
  authRegisterId,
  site,
  displayTheme,
  displayUpdate,
} from "@/services";

export default {
  namespaced: true,
  state: {
    spinning: false,
    step: 1,
    qr: {},
    formState: {
      site: "",
      phases: [],
      themeId: "",
    },
    sites: [],
    option: {
      theme: [],
      site: [],
      phase: [],
    },
    language: localStorage.getItem("language_signage")
      ? localStorage.getItem("language_signage")
      : "en",
    user: null,
    error: null,
  },

  actions: {
    async handleRegister({ commit }) {
      try {
        commit("SET_SPINNING", true);
        commit("SET_USER", null);
        localStorage.removeItem("token_signage");
        const response = await authRegister();
        if (response.code === 0) {
          commit("SET_QR", response.data);
          commit("SET_STEP", 1);
          commit("SET_SPINNING", false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleChangeLanguage({ commit, state }, value) {
      localStorage.setItem("language_signage", value);
      if (state.step === 2) {
        const response = await site();
        commit("SET_OPTION", {
          site: response.data.sites.map((item) => ({
            label: item.name,
            value: item._id,
            phases: item.phases,
            capacity: item.capacity,
            logo: item.logo,
          })),
        });
      }

      commit("SET_LANGUAGE", value);
    },
    async handleRegisterId({ commit, state }) {
      try {
        const response = await authRegisterId(state.qr._id);
        if (response.code === 0) {
          if (response.data) {
            commit("SET_USER", response.data.user);
            localStorage.setItem("token_signage", response.data.accessToken);
            const responseSite = await site();
            const responseTheme = await displayTheme();
            commit("SET_FORMSTATE", {
              site: responseSite.data.sites[0]._id,
              phases: responseSite.data.sites[0].phases.map((item) => item._id),
              themeId: responseTheme.data.themes[0]._id,
            });
            commit("SET_OPTION", {
              site: responseSite.data.sites.map((item) => ({
                label: item.name,
                value: item._id,
                phases: item.phases,
                capacity: item.capacity,
                logo: item.logo,
              })),
              phase: responseSite.data.sites[0].phases.map((item) => ({
                label: item.name,
                value: item._id,
              })),
              theme: responseTheme.data.themes.map((item) => ({
                label: item.name,
                value: item._id,
              })),
            });
            commit("SET_STEP", 2);
          }
        } else {
          localStorage.clear();
          commit("SET_STEP", 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleFinish({ commit, state }) {
      try {
        commit("SET_SPINNING", true);
        let currentSite = state.option.site.filter(
          (item) => item.value === state.formState.site
        );
        const payload = {
          site: state.formState.site,
          phases:
            currentSite[0].phases.length === state.formState.phases.length
              ? []
              : state.formState.phases,
          themeId: state.formState.themeId,
        };
        const response = await displayUpdate(payload);
        if (response.code === 0) {
          commit("SET_SPINNING", false);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleBack({ commit, state }) {
      let newStep;
      if (state.step === 4) {
        if (state.option.phase.length > 1) {
          newStep = 3;
        } else {
          newStep = state.option.site.length > 1 ? 2 : 1;
        }
      } else if (state.step === 3) {
        newStep = state.option.site.length > 1 ? 2 : 1;
      } else {
        newStep = 1;
      }
      commit("SET_STEP", newStep);
    },
    handleNext({ commit, state }) {
      let newStep;
      if (state.step === 3) {
        newStep = 4;
      } else {
        newStep = state.option.phase.length > 1 ? 3 : 4;
      }
      commit("SET_STEP", newStep);
    },
    handleClear({ commit }) {
      commit("SET_FORMSTATE", {
        phases: [],
      });
    },
    handleSelectAll({ commit, state }) {
      let phases = state.option.site
        .filter((item) => item.value === state.formState.site)[0]
        .phases.map((item) => item._id);
      commit("SET_FORMSTATE", {
        phases,
      });
    },
    handleChangeSite({ commit, state }, e) {
      let phases = state.option.site
          .filter((item) => item.value === e.target.value)[0]
          .phases.map((item) => item._id),
        phase = state.option.site
          .filter((item) => item.value === e.target.value)[0]
          .phases.map((item) => ({
            value: item._id,
            label: item.name,
          }));

      commit("SET_FORMSTATE", {
        site: e.target.value,
        phases,
      });
      commit("SET_OPTION", {
        phase,
      });
    },
  },

  mutations: {
    SET_SPINNING(state, data) {
      state.spinning = data;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_QR(state, data) {
      state.qr = data;
    },
    SET_STEP(state, data) {
      state.step = data;
    },
    SET_LANGUAGE(state, data) {
      state.language = data;
    },
    SET_OPTION(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.option[key] = value;
      });
    },
    SET_FORMSTATE(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.formState[key] = value;
      });
    },
  },
};
