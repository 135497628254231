<template>
  <div class="offcanvas bg-gradient offcanvas-end show">
    <div class="offcanvas-header">
      <div class="d-flex align-items-center">
        <button class="setting-btn p-0" @click="$emit('onBack')">
          <i class="bi bi-arrow-left d-flex"></i>
        </button>
        <div class="setting-title ff-kittithada fw-medium ms-3">
          {{ translate("title_theme") }}
        </div>
      </div>
    </div>
    <div class="offcanvas-body">
      <div class="row g-3">
        <div class="col-12" v-for="item in option" :key="item.value">
          <div
            class="setting-card-radio-theme position-relative overflow-hidden"
          >
            <input
              type="radio"
              name="theme"
              :value="item.value"
              v-model="selected"
            />
            <div class="setting-subtitle ff-kittithada fw-medium ms-5 mb-3">
              {{ item.label }}
            </div>
            <img
              :src="item.label === 'Betagen' ? BetagenImg : GreenerImg"
              alt="theme"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <div class="d-flex justify-content-between">
        <div>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="$emit('onBack')"
          >
            <i class="bi bi-chevron-left"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_back")
            }}</span>
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('onApply', selected)"
          >
            <i class="bi bi-check-lg"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_done")
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.setting-card-radio-theme {
  background-color: var(--color-white);
  border-radius: 1rem;
  padding: 1rem;
}
.setting-card-radio-theme img {
  border-radius: 0.5rem;
}
.setting-card-radio-theme input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -1rem;
  border-radius: 1rem;
  border: 0.083rem solid #e5e5e5;
}
.setting-card-radio-theme input:checked {
  border-color: var(--color-green);
}
.setting-card-radio-theme input::before {
  content: "";
  height: 1.667rem;
  width: 1.667rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  border: 0.083rem solid #e5e5e5;
  border-radius: 50%;
}
.setting-card-radio-theme input::after {
  content: "";
  height: 0.833rem;
  width: 0.833rem;
  position: absolute;
  top: calc(1rem + 0.4rem);
  left: calc(1rem + 0.4rem);
  background-color: var(--color-white);
  border-radius: 50%;
}
.setting-card-radio-theme input:checked:before {
  border-color: var(--color-green);
}
.setting-card-radio-theme input:checked:after {
  background-color: var(--color-green);
}
</style>

<script setup>
import { defineProps, ref } from "vue";
import { translate } from "@/services";
import BetagenImg from "@/assets/themes/betagen.png";
import GreenerImg from "@/assets/themes/greener.png";

let props = defineProps({
  option: Array,
  value: String,
});
const selected = ref(props.value);
</script>
