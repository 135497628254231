<template>
  <div
    class="w-100 h-100 d-flex align-items-center justify-content-center bg-gradient"
  >
    <span class="loader"></span>
  </div>
</template>

<style scoped>
.loader {
  width: 10vw;
  height: 10vw;
  border: 0.75rem dotted var(--color-green);
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 0.75rem dotted var(--color-green);
  border-style: solid solid dotted;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
