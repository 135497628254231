<template>
  <header class="header-auth position-absolute top-0 start-0 end-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="dropdown d-flex align-items-center">
            <button
              class="btn dropdown-toggle d-flex align-items-center"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                class="header-auth-flag"
                :src="auth.language === 'th' ? FlagTh : FlagEn"
                alt="flag"
              />
              <span class="ff-kittithada text-uppercase mx-2">{{
                auth.language
              }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="handleChangeLanguage('en')"
                >
                  <img class="header-auth-flag" :src="FlagEn" alt="flag" />
                  <span class="ff-kittithada text-uppercase ms-2">en</span>
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="handleChangeLanguage('th')"
                >
                  <img class="header-auth-flag" :src="FlagTh" alt="flag" />
                  <span class="ff-kittithada text-uppercase ms-2">th</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col text-center">
          <div class="header-auth-title ff-kittithada fw-medium">
            <span v-if="auth.step === 2">{{
              translate("title_select_site")
            }}</span>
            <span v-if="auth.step === 3">{{
              translate("title_select_phase")
            }}</span>
            <span v-if="auth.step === 4">{{
              translate("title_select_theme")
            }}</span>
          </div>
        </div>
        <div class="col" v-if="auth.user">
          <div class="d-flex align-items-center justify-content-end">
            <img
              class="header-auth-avatar"
              src="@/assets/icons/avatar.png"
              alt="avatar"
            />
            <span class="ms-2 header-auth-username ff-kittithada">{{
              auth.user.displayName
            }}</span>
          </div>
        </div>
        <div class="col text-end" v-else>
          <img
            class="header-auth-logo"
            src="@/assets/logo/banpunext.png"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </header>
  <main class="main-auth bg-gradient d-flex align-items-center">
    <div class="container-fluid">
      <slot />
    </div>
  </main>
  <div class="wrapper-box position-absolute bottom-0 end-0" v-if="!auth.user">
    <img src="@/assets/images/box.png" alt="box" class="img-fluid" />
  </div>
</template>

<style scoped></style>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { translate } from "@/services";
import FlagEn from "@/assets/flags/en.png";
import FlagTh from "@/assets/flags/th.png";

const store = useStore();
const auth = computed(() => {
  return store.state.auth;
});

async function handleChangeLanguage(value) {
  await store.dispatch("auth/handleChangeLanguage", value);
}
</script>
