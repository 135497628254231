export function formatUnit(value, type) {
  if (type !== "saving") {
    if (value < 100000)
      return value
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else {
      let number;
      if (type === "water") number = (value / 100).toFixed(0);
      else {
        number = (value / 1000).toFixed(0);
      }
      return type === "tree"
        ? number.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "k"
        : number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    if (value < 1000000)
      return value
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else {
      let number = (value / 1000000).toFixed(2);
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "m";
    }
  }
}

export function formatNumber(value, decimal = 0) {
  return !isNaN(value)
    ? value
        .toFixed(decimal)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
}

export function showTimeout(second) {
  var m = Math.floor(second / 60);
  var s = second - m * 60;

  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return m + ":" + s;
}

export function showTime(value) {
  if (!localStorage.getItem("language_signage"))
    localStorage.setItem("language_signage", "en");

  var date = new Date();
  var dayName = {
    en: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    th: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
  };
  var monthName = {
    en: [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEP",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ],
    th: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  };

  var D = date.getDay();
  var d = date.getDate();
  var M = date.getMonth();
  var h = date.getHours();
  var m = date.getMinutes();
  var y = date.getFullYear();

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  y = localStorage.getItem("language_signage") === "en" ? y : y + 543;

  var clockBetagen =
    monthName[localStorage.getItem("language_signage")][M] +
    " " +
    y +
    "<br/>" +
    d +
    " " +
    dayName[localStorage.getItem("language_signage")][D] +
    " " +
    h +
    '<span class="separator">:</span>' +
    m;

  var clockGreener =
    d +
    " " +
    monthName[localStorage.getItem("language_signage")][M] +
    " " +
    y +
    " | " +
    h +
    '<span class="separator">:</span>' +
    m;

  return value === "betagen" ? clockBetagen : clockGreener;
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function generateThemeClass(name) {
  return name.toLowerCase().split(" ").join("_");
}

export function formatThemeName(key, data) {
  return data.filter((item) => item.value === key)[0].label;
}

export function formatLanguageName(key) {
  var languageName = {
    th: {
      th: "ภาษาไทย",
      en: "ภาษาอังกฤษ",
    },
    en: {
      th: "Thai",
      en: "English",
    },
  };
  return languageName[localStorage.getItem("language_signage")][key];
}
