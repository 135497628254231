import store from "@/store";
import Axios from "axios";

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  headers: {
    "x-api-key": process.env.VUE_APP_API_KEY,
  },
  timeout: 120000,
});
const axios = instance;

axios.interceptors.request.use(
  (config) => {
    if (!localStorage.getItem("language_signage"))
      localStorage.setItem("language_signage", "en");

    const token = localStorage.getItem("token_signage");
    const language = localStorage.getItem("language_signage");
    const auth = token ? `Bearer ${token}` : "";
    config.headers.Authorization = auth;
    config.headers["x-language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (store.state.auth.error) store.state.auth.error = null;
    return response;
  },
  (error) => {
    if (error.response) {
      store.state.auth.error = error.message;
    } else {
      store.state.auth.error = "ขาดการเชื่อมต่อสัญญาณ internet";
    }
    return Promise.reject(error);
  }
);

export { axios };
