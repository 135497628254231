import { axios } from "../axios";

export const authRegister = async (payload = {}) => {
  try {
    const { data } = await axios.post(`/auth/register`, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const authRegisterId = async (_id) => {
  try {
    const { data } = await axios.post(`/auth/register/${_id}`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
