<template>
  <div
    class="w-100 h-100 d-flex align-items-center justify-content-center bg-gradient"
  >
    <h3 class="text-center">
      รองรับอุปกรณ์ที่มีขนาด ratio 16:9 หรือ 9:16 เท่านั้น
    </h3>
  </div>
</template>

<style scoped>
h3 {
  color: var(--color-green);
  font-size: 2rem;
}
</style>
