<template>
  <HeaderComponent />
  <main
    v-if="display.class === 'betagen'"
    class="main-app d-flex align-items-center"
    :style="{
      'background-image': site.image ? 'url(' + site.image + ')' : 'none',
    }"
  >
    <div class="overlay" />
    <div class="container-fluid p-0">
      <slot />
    </div>
  </main>
  <main
    v-if="display.class === 'green_world'"
    :class="`main-app bg-gradient-${display.layout} d-flex align-items-center`"
  >
    <div class="overlay" />
    <div class="container-fluid p-0">
      <slot />
    </div>
  </main>
</template>

<script setup>
import { onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import HeaderComponent from "@/components/HeaderComponent.vue";

let intervalClock = 0;
const store = useStore();
const display = computed(() => {
  return store.state.display;
});
const site = computed(() => {
  return store.state.site;
});

onMounted(async () => {
  await store.dispatch("site/handleInfo");
  await store.dispatch("display/handleDisplay");
  document.body.classList.add(display.value.class);
  intervalClock = setInterval(() => {
    store.dispatch("display/handleClock");
  }, 1000);
});
onUnmounted(() => {
  clearInterval(intervalClock);
});
</script>
