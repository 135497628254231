import { createStore } from "vuex";
import auth from "./modules/auth";
import data from "./modules/data";
import display from "./modules/display";
import site from "./modules/site";

export default createStore({
  modules: {
    auth,
    data,
    display,
    site,
  },
});
