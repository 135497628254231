<template>
  <template v-if="loading">
    <loading-component />
  </template>
  <template v-else>
    <template v-if="display.layout === 'notsupport'">
      <not-support-component />
    </template>
    <template v-else>
      <component :is="$route.meta.layout || 'div'">
        <router-view />
      </component>
      <div class="alert alert-danger" role="alert" v-if="auth.error">
        {{ auth.error }}
      </div>
    </template>
  </template>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import LoadingComponent from "@/components/LoadingComponent.vue";
import NotSupportComponent from "@/components/NotSupportComponent.vue";

const store = useStore();
const auth = computed(() => {
  return store.state.auth;
});
const display = computed(() => {
  return store.state.display;
});
const loading = ref(true);

onMounted(() => {
  store.dispatch("display/handleLayout", {
    width: window.innerWidth,
    height: window.innerHeight,
  });
  loading.value = false;
  window.addEventListener("resize", onResize);
});
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});

function onResize() {
  loading.value = true;
  setTimeout(() => {
    store.dispatch("display/handleLayout", {
      width: window.innerWidth,
      height: window.innerHeight,
    });
    loading.value = false;
  }, 1000);
}
</script>
