import { axios } from "../axios";

export const dataEnergy = async () => {
  try {
    const { data } = await axios.get(`/data/energy`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const dataCarbon = async () => {
  try {
    const { data } = await axios.get(`/data/carbon`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const dataSaving = async () => {
  try {
    const { data } = await axios.get(`/data/saving`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
