<template>
  <div class="offcanvas bg-gradient offcanvas-end show">
    <div class="offcanvas-header">
      <div class="d-flex align-items-center">
        <button class="setting-btn p-0" @click="$emit('onBack')">
          <i class="bi bi-arrow-left d-flex"></i>
        </button>
        <div class="setting-title ff-kittithada fw-medium ms-3">
          {{ translate("title_phase") }}
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-0">
      <div
        class="setting-card-checkbox-phase position-relative overflow-hidden"
        v-for="item in data.option.phase"
        :key="item.value"
      >
        <input
          type="checkbox"
          name="phase"
          :value="item.value"
          v-model="selected"
        />
        <div class="setting-subtitle ff-kittithada">{{ item.label }}</div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <div class="d-flex justify-content-between">
        <div>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="$emit('onBack')"
          >
            <i class="bi bi-chevron-left"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_back")
            }}</span>
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('onApply', selected)"
            :disabled="!selected.length"
          >
            <i class="bi bi-check-lg"></i>
            <span class="ff-kittithada fw-medium ms-3">{{
              translate("title_button_done")
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.setting-card-checkbox-phase {
  padding: 1.667rem 2rem;
}
.setting-card-checkbox-phase input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -1.667rem -2rem;
}
.setting-card-checkbox-phase input::before {
  content: "";
  height: 1.667rem;
  width: 1.667rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  border: 0.083rem solid #e5e5e5;
  border-radius: 50%;
  transform: translate(0, -50%);
}
.setting-card-checkbox-phase input::after {
  content: "\2713";
  font-size: 1.2rem;
  height: 1.667rem;
  width: 1.667rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  border-radius: 50%;
  transform: translate(0, -50%);
  color: var(--color-white);
  text-align: center;
}
.setting-card-checkbox-phase input:checked:before {
  border-color: var(--color-green);
}
.setting-card-checkbox-phase input:checked:after {
  background-color: var(--color-green);
}
</style>

<script setup>
import { defineProps, ref } from "vue";
import { translate } from "@/services";

let props = defineProps({
  data: Object,
});

const selected = ref(props.data.formState.phases);
</script>
