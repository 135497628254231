import { axios } from "../axios";

export const site = async () => {
  try {
    const { data } = await axios.get(`/sites`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const siteInfo = async () => {
  try {
    const { data } = await axios.get(`/sites/info`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
