import { siteInfo } from "@/services";

export default {
  namespaced: true,
  state: {
    data: null,
    name: "",
    image: null,
  },
  actions: {
    async handleInfo({ commit }) {
      try {
        const response = await siteInfo();
        if (response.code === 0) {
          commit("SET_DATA", response.data);
          commit("SET_NAME", response.data.site.name);
          commit(
            "SET_IMAGE",
            response.data.site.images.length
              ? response.data.site.images[0]
              : null
          );
        } else {
          localStorage.clear();
          window.location.href = "/auth/setup";
        }
      } catch (error) {
        localStorage.clear();
        window.location.href = "/auth/setup";
      }
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_NAME(state, data) {
      state.name = data;
    },
    SET_IMAGE(state, data) {
      state.image = data;
    },
  },
};
