import { axios } from "../axios";

export const display = async () => {
  try {
    const { data } = await axios.get(`/display`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const displayUpdate = async (payload) => {
  try {
    const { data } = await axios.patch(`/display`, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const displayTheme = async () => {
  try {
    const { data } = await axios.get(`/display/themes`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const displayLogout = async () => {
  try {
    const { data } = await axios.delete(`/display`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
