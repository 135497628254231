import {
  display,
  displayTheme,
  displayUpdate,
  displayLogout,
  site,
  siteInfo,
} from "@/services";
import { showTime, generateThemeClass } from "@/helpers";

export default {
  namespaced: true,
  state: {
    class: "",
    clock: "",
    open: false,
    openSite: false,
    openPhase: false,
    openTheme: false,
    openLanguage: false,
    user: null,
    formState: {
      site: "",
      phases: [],
      themeId: "",
      language: "",
      siteName: "",
      phaseName: "",
      selectSite: "",
    },
    option: {
      site: [],
      phase: [],
      theme: [],
    },
    layout: "",
  },
  actions: {
    handleClock({ commit, state }) {
      commit("SET_CLOCK", showTime(state.class));
    },
    handleLayout({ commit }, value) {
      if (value.width === 1080 && value.height === 1280) {
        commit("SET_LAYOUT", "other");
      } else {
        // if (
        //   (value.width >= 1280 && value.height >= 720) ||
        //   (value.width >= 720 && value.height >= 1280)
        // ) {
        //   var ratio = Math.round((value.width / value.height) * 100) / 100;
        //   if (ratio === 1.78) {
        //     commit("SET_LAYOUT", "landscape");
        //   } else if (ratio === 0.56) {
        //     commit("SET_LAYOUT", "portrait");
        //   } else {
        //     commit("SET_LAYOUT", "landscape");
        //   }
        // } else {
        //   commit("SET_LAYOUT", "landscape");
        //   //commit("SET_LAYOUT", "notsupport");
        // }

        commit(
          "SET_LAYOUT",
          value.width > value.height ? "landscape" : "portrait"
        );
      }
    },
    async handleDisplay({ commit }) {
      try {
        const response = await display();
        if (response.code === 0) {
          commit("SET_USER", response.data.user);
          commit("SET_FORMSTATE", {
            site: response.data.site,
            phases: response.data.phases ? response.data.phases : [],
            themeId: response.data.theme,
          });
          const responseTheme = await displayTheme();
          if (responseTheme.code === 0) {
            commit("SET_OPTION", {
              theme: responseTheme.data.themes.map((item) => ({
                label: item.name,
                value: item._id,
              })),
            });
            commit(
              "SET_CLASS",
              generateThemeClass(
                responseTheme.data.themes.filter(
                  (item) => item._id === response.data.theme
                )[0].name
              )
            );
            if (location.pathname === "/auth/setup") window.location.href = "/";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleOpen({ commit }) {
      try {
        const responseDisplay = await display();
        const responseSite = await site();
        const responseSiteInfo = await siteInfo();
        let phaseName = "";

        if (
          responseDisplay.data.phases &&
          responseDisplay.data.phases.length === 1
        ) {
          phaseName = responseSite.data.sites
            .filter((item) => item._id === responseDisplay.data.site)[0]
            .phases.filter(
              (item) => item._id === responseDisplay.data.phases[0]
            )[0].name;
        }
        if (!responseDisplay.data.phases) {
          phaseName = responseSite.data.sites.filter(
            (item) => item._id === responseDisplay.data.site
          )[0].phases[0].name;
        }

        commit("SET_OPTION", {
          site: responseSite.data.sites.map((item) => ({
            label: item.name,
            value: item._id,
            phases: item.phases,
            logo: item.logo,
            capacity: item.capacity,
          })),
        });
        commit("SET_FORMSTATE", {
          themeId: responseDisplay.data.theme,
          phases: responseDisplay.data.phases
            ? responseDisplay.data.phases
            : responseSite.data.sites
                .filter((item) => item._id === responseDisplay.data.site)[0]
                .phases.map((item) => item._id),
          siteName: responseSiteInfo.data.site.name,
          phaseName,
          site: responseDisplay.data.site,
          language: localStorage.getItem("language_signage"),
        });
        commit("SET_OPEN", true);
      } catch (error) {
        console.log(error);
      }
    },

    handleOpenSite({ commit, state }) {
      try {
        commit("SET_FORMSTATE", { selectSite: state.formState.site });
        commit("SET_OPEN_SITE", true);
      } catch (error) {
        console.log(error);
      }
    },

    handleOpenPhase({ commit, state }) {
      try {
        commit("SET_OPTION", {
          phase: state.option.site
            .filter((item) => item.value === state.formState.site)[0]
            .phases.map((item) => ({ label: item.name, value: item._id })),
        });
        commit("SET_OPEN_PHASE", true);
      } catch (error) {
        console.log(error);
      }
    },

    handleOpenTheme({ commit }) {
      commit("SET_OPEN_THEME", true);
    },

    handleOpenLanguage({ commit }) {
      commit("SET_OPEN_LANGUAGE", true);
    },

    handleSwitchTheme({ commit }, value) {
      commit("SET_FORMSTATE", { themeId: value });
    },

    handleSwitchLanguage({ commit, state }) {
      commit("SET_FORMSTATE", {
        language: state.formState.language === "en" ? "th" : "en",
      });
    },

    async handleApply({ state }) {
      let payload;
      localStorage.setItem("language_signage", state.formState.language);

      payload = {
        site: state.formState.site,
        phases: state.formState.phases,
        themeId: state.formState.themeId,
      };

      await displayUpdate(payload);
      window.location.reload();
    },

    handleApplySite({ commit, state }, value) {
      let phases = state.option.site
          .filter((item) => item.value === value)[0]
          .phases.map((item) => item._id),
        phaseName =
          phases.length === 1
            ? state.option.site.filter((item) => item.value === value)[0]
                .phases[0].name
            : "";

      commit("SET_FORMSTATE", {
        site: value,
        siteName: state.option.site.filter((item) => item.value === value)[0]
          .label,
        phases,
        phaseName,
      });
      commit("SET_OPEN_SITE", false);
    },
    handleApplyPhase({ commit, state }, value) {
      commit("SET_FORMSTATE", {
        phases: value,
        phaseName:
          value.length === 1
            ? state.option.site
                .filter((item) => item.value === state.formState.site)[0]
                .phases.filter((item) => item._id === value[0])[0].name
            : state.formState.phaseName,
      });
      commit("SET_OPEN_PHASE", false);
    },
    handleApplyTheme({ commit }, value) {
      commit("SET_FORMSTATE", {
        themeId: value,
      });
      commit("SET_OPEN_THEME", false);
    },
    handleApplyLanguage({ commit }, value) {
      commit("SET_FORMSTATE", {
        language: value,
      });
      commit("SET_OPEN_LANGUAGE", false);
    },

    async handleLogout() {
      try {
        const response = await displayLogout();
        if (response.code === 0) {
          localStorage.clear();
          window.location.href = "/auth/setup";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_CLOCK(state, data) {
      state.clock = data;
    },
    SET_CLASS(state, data) {
      state.class = data;
    },
    SET_OPEN(state, data) {
      state.open = data;
    },
    SET_OPEN_SITE(state, data) {
      state.openSite = data;
    },
    SET_OPEN_PHASE(state, data) {
      state.openPhase = data;
    },
    SET_OPEN_THEME(state, data) {
      state.openTheme = data;
    },
    SET_OPEN_LANGUAGE(state, data) {
      state.openLanguage = data;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_FORMSTATE(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.formState[key] = value;
      });
    },
    SET_OPTION(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.option[key] = value;
      });
    },
    SET_LAYOUT(state, data) {
      state.layout = data;
    },
  },
};
